.nav-background {
  background-color: #28465ac2;
}

.navbar-brand, .navbar-light, .nav-link {
  color: white !important;
}

a.active {
  border-bottom: 2px solid #ffffff;
  transition-property: opacity, transform;
  transition-duration: 0ms, 0.3s;
  transition-timing-function: ease, ease;
  transition-delay: 0.5ms, 0.5s;
}