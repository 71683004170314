.amenitiesContainer {
  justify-content: space-between;

}
.amenity {
  padding: 2% 2% 2% 0%;
  flex-basis: 25%;
  display: flex; /* so child elements can use flexbox stuff too! */
  align-items: center;
}

.amenity-description {
  margin-left: 10px;
}

@media all and (max-width: 768px) {
  .amenity {
    flex-basis: 30%;
  }
}