.element-container {
  border: none;
}

.card-container:before {
  content: "";
  position: absolute;
  top: 730px;
  left: 155px;
  width: 1px;
  height: calc(100% - 54px);
  background-color: black;
  /* background-color: hsla(0, 0%, 93.3%, .2); */
}

@keyframes example {
  from {transform: translateY(100%);}
  to {transform: translateY(-100);}
}

.hero-image {
  animation-name: example;
  animation-duration: 3s;
  animation-timing-function: ease;
}

.ring {
  background: linear-gradient(135deg, #133EE3 0%, #7532E1 100%);
}
.ring-1 {
  width: 186px; 
  height: 186px; 
  opacity: .2;
}
.ring-2 {
  width: 325px; 
  height: 325px;
  opacity: .2;
}
.ring-3 {
  width: 424px; 
  height: 424px;
  opacity: .4;
}
.ring-4 {
  width: 682px; 
  height: 682px;
  opacity: .2; 
}
.ring-5 {
  width: 996px; 
  height: 996px;
  opacity: .05; 
}

.rounded-full {
  border-radius: 9999px;
}

.absolute {
  position: absolute;
}

.home-anim-hero-rings-container {
  position: fixed;
  top: 565px;
}

.w-screen {
  width: 100vw;
}

.left-0 {
  left: 0;
}

.home-anim-hero-rings {
  transition: transform 2s ease,opacity .85s,-webkit-transform 2s ease;
}

.pin-hc {
  left: 50%;
  transform: translateX(-50%);
}

.home-anim-hero-ring:first-child {
  opacity: .2;
}

.home-anim-hero-ring {
  transition: opacity .85s;
}

.pin-c {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.container-fluid {
  flex-grow: 1;
}

.main-section {
  display: flex;
  order: 1;
  padding: 2%;
  flex-grow: 1;
  flex-basis: 76%;
  flex-flow: column wrap;
}

.main-section h1 {
  flex-grow: 1;
}

.checkout-section {
  display: flex;
  flex-flow: row wrap;
  order: 2;
  z-index: 1200;
  background: #ffffff;
  opacity: 1.0;
  /* flex: 500px; */
  /* padding: 2%; */
}

.DateRangePicker, .DateRangePickerInput {
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}

.form-control, .DateRangePicker, .DateRangePickerInput {
  margin: 10px 0px;
}

@media all and (max-width: 768px) {
  .photo-grid-item {
    flex-basis: 100%;
  }
  
  /* .form-control, .DateRangePicker, .DateRangePickerInput, .DateRangePicker div {
    width: 100% !important;
  } */

  .checkout-section {
    order: 0;
  }
}

@media all and (max-width: 1300px) {
  .checkout-section {
    order: 0;
    flex-flow: column wrap;
    flex-grow: 1;
  }
}

