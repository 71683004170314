.checkout {
  width: 100%;
}

.form-container {
  text-align: center;
}

.please-wait-spinner {
  display: none;
}