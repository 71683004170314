h3 {
  flex-grow: 1;
}

.about-description {
  text-align: justify;
  text-justify: inter-word;
}

.list-item-separator {
  border-right: 1px solid #655e8d;
  padding-right: 10px;
}

ul li {
  padding: 0px 5px 0px 0px;
}

@media all and (max-width: 768px) {
  .no-mobile {
    display: none;
  }
  
  .list-item-separator-no-mobile {
    border-right: none;
  }
}