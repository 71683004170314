.photo-grid-section {
    display: flex;
    flex-wrap: wrap;
}
  
.photo-grid-item {
    padding: 1% 1% 1% 0%;
    flex-grow: 1;
    flex-basis: 22%;
    display: flex;
 }
  
.photo-grid-item img {
    max-width: 100%;
}

.photo-carousel-section {
    display: none;
}
  
@media all and (max-width: 768px) {
  .photo-grid-section {
      display: none;
  }

  .photo-carousel-section {
      display: block;
  }
}

@media all and (max-width: 1300px) {
  
}