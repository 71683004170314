.body {
  background-color: #06182b;
  color: white;
}

.App {
  /* text-align: center; */
  padding: 0px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.z-1 {
  z-index: -1;
}

.absolute {
  position: absolute
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width: 1200px) {
  
}

@media all and (min-width: 600px) {
  
}