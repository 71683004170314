.roomsContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.room {
  padding: 2% 2% 2% 0%;
  flex-basis: 33%;
}

.roomTypeContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0% 2% 0% 0%;
}

.roomIcon {
  margin: 5px;
}

.roomType {
  flex-grow: 1;
}